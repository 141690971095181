
import { Component, Vue } from 'vue-property-decorator'
@Component({
  name: 'Statistics'
})
export default class MyOrder extends Vue {
  private searchInfo = {
    yearMonth: '',
    projectId: '',
    type: ''
  }

  private statistic = {}
  private list: any = []
  private week = ['日', '一', '二', '三', '四', '五', '六']
  private types = [{
    name: '自建工人',
    value: '1'
  },
  {
    name: '班组考勤',
    value: '2'
  }]

  private currentMonth = this.$dayjs().format('YYYY-MM')

  get projectList () {
    return this.$store.state.projectList
  }

  created () {
    this.searchInfo.yearMonth = this.currentMonth
    this.load()
  }

  onSearch () {
    this.currentMonth = this.searchInfo.yearMonth
    this.list = []
    this.load()
  }

  load () {
    this.$axios.get(this.$apis.laborManage.selectYhAttendanceCountByWeb, {
      ...this.searchInfo
    }).then((res) => {
      this.statistic = res
      this.formatList(res.list || [])
    })
  }

  formatList (arr: any) {
    this.list = arr
    const startLen = this.$dayjs(this.currentMonth).day()
    const totalLen = this.list.length + startLen > 35 ? 42 : 35
    const aStart = new Array(startLen)
    const aEnd = new Array(totalLen - this.list.length - startLen)
    aStart.length && aStart.fill({})
    aEnd.length && aEnd.fill({})
    this.list = aStart.concat(this.list)
    this.list = this.list.concat(aEnd)
  }

  changeMoth (num: any) {
    this.currentMonth = this.$dayjs(this.currentMonth).add(num, 'month').format('YYYY-MM')
  }
}
